.btn {
  width: 11.5rem;
  height: 3.7rem;
  border-radius: 5px;
  font-size: 1.2rem;
  color: white;
  transition: ease all 0.3s;
}
.btn:hover {
  color: white !important;
  opacity: 0.9;
}
.btn-md {
  height: 3rem;
  width: 8rem;
  font-size: 1rem;
  border-radius: 10px;
}
.btn-aqua {
  background: #51a8b1;
}
.btn-purple {
  background: #9f4c89;
}
.btn-green {
  background: #a8cf45;
}
