/* You can add global styles to this file, and also import other style files */
@import url(./button.scss);

@font-face {
  font-family: "circular-bd";
  src: url("./assets/fonts/euclid-circular-a-cufonfonts-webfont/Euclid\ Circular\ A\ Bold.woff");
}

@font-face {
  font-family: "circular-md";
  src: url("./assets/fonts/euclid-circular-a-cufonfonts-webfont/Euclid\ Circular\ A\ Medium.woff");
}
@font-face {
  font-family: "circular-rg";
  src: url("./assets/fonts/euclid-circular-a-cufonfonts-webfont/Euclid\ Circular\ A\ Regular.woff");
}
*,
body {
  margin: 0;
  padding: 0;
}
body {
  font-family: "circular-rg";
  color: #504f4f;
}

html {
  font-size: 16px;
  @media (max-width: 900px) {
    font-size: 14px;
  }
  @media (max-width: 700px) {
    font-size: 12px;
  }
}

.click {
  cursor: pointer;
}

.font-bold {
  font-weight: 600;
  font-family: "circular-bd" !important;
}
.font-medium {
  font-weight: 500;
  font-family: "circular-md" !important;
}
.text-darkblue {
  color: #2e315e !important;
}
.text-green {
  color: #a8cf45 !important;
}
.text-aqua {
  color: #51a8b1 !important;
}
.text-hibiscus {
  color: #9f4c89 !important;
}
.text-grey {
  color: #a3a5c4 !important;
}
.text-sm {
  font-size: 0.9rem !important;
}
.text-lg {
  font-size: 1.2rem !important;
}
.text-xl {
  font-size: 1.5rem !important;
}
.text-2xl {
  font-size: 2rem !important;
}
.text-3xl {
  font-size: 4rem !important;
}
.border-radius {
  border-radius: 5px;
}
.bg-grey {
  background: #fafafd;
}

.form-control {
  background: #fff;
  border: 2px solid rgba(224, 224, 224, 0.8);
  border-radius: 4px;
  min-height: 50px;
  &:focus,
  &:active {
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid #1070b7;
  }

  &::placeholder {
    color: #8b9eb0;
    font-size: 14px;
  }
}
.line-height-1 {
  line-height: 1;
}
